<template>
    <BlockUI :blocked="loading">
        <FullCalendar :options="options">
            <template v-slot:eventContent='arg'>
                <div class="text-center">
                    <span class="text-4xl font-bold text-red-500">{{ arg.event.title }}</span>
                    <br>
                    <span class="text-lg text-blue-500">{{ arg.event.extendedProps.desc_durasi }} </span>
                </div>
            </template>
        </FullCalendar>
    </BlockUI>
</template>

<script>
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'


export default {
	components: {
		'FullCalendar': FullCalendar,
	},
    props: [
		"filter",
    ],
	data() {
		return {		
            // loading
            loading: false,
            
			options: {
                plugins: [ dayGridPlugin, interactionPlugin ],
                initialView: 'dayGridMonth',
                events: null,
                // eventClick: this.handleEventClick
            },
		}
	},
	mounted() {
        this.getData();
	},
    watch: {
        filter: {
            handler() {
                setTimeout(() => {
                    this.getData();
                }, 500);
            },
            deep: true
        },
    },
    methods: {
        getData(){

            this.loading= true;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/dashboard/mjp-vs-actual',
                params: {
                    "userid": this.filter.userid,
                }
            })
            .then(res => {
                this.options.events = res.data.data;
                this.loading= false;
                
            })
            .catch((err) => {
                this.loading= false;
                console.log(err);
            });
        },
        // handleEventClick(info) {
        //     const eventObj = info.event;

        //     console.log(eventObj);

        //     if (eventObj.url) {
        //         alert(
        //         `Clicked ${eventObj.title}.\nWill open ${eventObj.url} in a new tab`,
        //         'Event Clicked',
        //         {
        //             confirmButtonText: 'OK',
        //             type: 'info'
        //         }
        //         ).then(() => {
        //             window.open(eventObj.url);
        //         });

        //         info.jsEvent.preventDefault();
        //     } else {
        //         alert(`Clicked ${eventObj.title}`, 'Event Clicked', {
        //             confirmButtonText: 'OK',
        //             type: 'info'
        //         });
        //     }
        // }
    }
}
</script>