<template>
    <BlockUI :blocked="loading">
        <p class="text-center">Total Activity By Status</p>
        <Chart type="pie" :data="data" :options="options"/>
    </BlockUI>
</template>

<script>

export default {
    props: [
		"filter",
		// "startDate",
		// "endDate",
    ],
    data() {
        return {
            // loading
            loading: false,

            data: null,
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        labels: {
                            fontColor: '#495057'
                        }
                    }
                }
            },
        }
    },
	mounted() {
        this.getData();
	},
    computed:{
    },
    watch: {
        filter: {
            handler() {
                setTimeout(() => {
                    this.getData();
                }, 500);
            },
            deep: true
        },
        // endDate: {
        //     handler() {
        //         setTimeout(() => {
        //             if(
        //                 this.endDate !== null && 
        //                 this.endDate !== 'Invalid date'
        //             ){  
        //                 this.getData();
        //             }
        //         }, 500);
        //     },
        // }
    },
	methods: {
        getData(){

            this.loading= true;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/dashboard/account-development',
                params: {
                    // "provinsi_id": this.filter.provinsi_id,
                    // "homebase_id": this.filter.homebase_id,
                    "userid": this.filter.userid,
                    // "tanggal_mulai": this.startDate,
                    // "tanggal_akhir": this.endDate,
                }
            })
            .then(res => {
                this.data = res.data.data;
                this.loading= false;
                
            })
            .catch((err) => {
                this.loading= false;
                console.log(err);
            });
        },
	},
}
</script>

<style>
#chartjs-tooltip{
    background: #ddd;
    padding: 8px
}
.external-tooltip {
  position: absolute;
  font-family: Arial, sans-serif;
  font-size: 12px;
  z-index: 9999;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;
}

.external-tooltip table {
  width: 100%;
  margin: 0;
  padding: 0;
  border-collapse: collapse;
}

.external-tooltip th,
.external-tooltip td {
  padding: 6px 8px;
  text-align: left;
}

.external-tooltip th {
  background-color: #333;
  color: #fff;
  font-weight: bold;
}

.external-tooltip td {
  background-color: #fff;
  border-top: 1px solid #ddd;
}

.external-tooltip td span {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 6px;
  vertical-align: middle;
}

.external-tooltip .tooltip-title {
  font-weight: bold;
  margin-bottom: 6px;
}

.external-tooltip .tooltip-body {
  margin-top: 6px;
}
</style>