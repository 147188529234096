<template>
	<Error :errors="errors"/>
	<div class="grid">
		<div class="col-12 lg:col-12 xl:col-12">
			<div class="surface-section px-4 py-8 md:px-6 lg:px-8">
				<div class="text-700 text-center">
					<div class="text-blue-600 font-bold mb-3">&nbsp;SELAMAT DATANG DI</div>
					<div class="text-900 font-bold text-5xl mb-3">Web Report - {{appsName}}</div>
				</div>
			</div>
		</div>
	</div>

	<div class="grid">
		<div class="col-12 md:col-6 lg:col-3">
			<UserAktif/>
        </div>
		<div class="col-12 md:col-6 lg:col-3">
			<ASMPlans/>
        </div>
		<div class="col-12 md:col-6 lg:col-3">
			<ComplianceToday/>
        </div>
		<div class="col-12 md:col-6 lg:col-3">
			<ComplianceMtd/>
        </div>
	</div>
	
	<div class="card ">
		<h5>Filter</h5>
		<hr>
		<div class="p-fluid">
			<div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>User</label>
                    <MultiSelect id="userid" dataKey="userid" v-model="userids" :loading="loadingDropdownUser"
                        :options="dataDropdownUser"
                        optionLabel="nama" placeholder="Pilih User" :filter="true" :showToggleAll="false"
                        :showClear="true" @filter="searchDropdownUser($event, 'filter')"/>
                </div>
			</div>
		</div>
        <Button label="Clear Filter" icon="pi pi-times" class="p-button-warning mr-2 my-1" @click="clearFilter"/>
	</div>

	<div class="grid">
		<div class="col-12 md:col-6 lg:col-6">
			<div class="grid">
				<div class="col-12 md:col-12 lg:col-12">
					<div class="card ">
						<h4>TIME SPENT ACTIVITY</h4>
						<TimeSpentActivityChart :filter="filters"/>
					</div>
				</div>
			</div>
			<div class="grid">
				<div class="col-12 md:col-12 lg:col-12">
					<div class="card ">
						<h4>SALESMAN RATING</h4>
						<SalesmanRatingChart :filter="filters"/>
					</div>
				</div>
			</div>
        </div>
		<div class="col-12 md:col-6 lg:col-6">
			<div class="grid">
				<div class="col-12 md:col-12 lg:col-12">
					<div class="card ">
						<h4>ACCOUNT DEVELOPMENT</h4>
						<AccountDevelopmentChart :filter="filters"/>
					</div>
				</div>
			</div>
        </div>
	</div>
	<div class="grid">
		<div class="col-12 md:col-12 lg:col-12">
			<div class="card ">
				<h4>MJP VS ACTUAL</h4>
				<MjpActualCalendar :filter="filters"/>
			</div>
        </div>
	</div>
	<div class="grid">
		<div class="col-12 md:col-12 lg:col-12">
			<div class="card ">
				<h4>MAPS - ACCOUNT DEVELOPMENT</h4>
				<AccountDevelopmentMap :filter="filters"/>
			</div>
        </div>
	</div>
	<!-- <div class="grid">
		<div class="col-12 md:col-12 lg:col-12">
			<div class="card ">
				<h4>MAPS - TESTING POLYGON</h4>
				<TestingPolygonMap :filter="filters"/>
			</div>
        </div>
	</div>
	<div class="grid">
		<div class="col-12 md:col-12 lg:col-12">
			<div class="card ">
				<h4>MAPS - TESTING CLUSTER</h4>
				<TestingClusterMap :filter="filters"/>
			</div>
        </div>
	</div> -->
</template>

<script>
import { mapGetters } from "vuex";
import AccountDevelopmentMap from '../components/dashboard/AccountDevelopmentMap.vue';
import TimeSpentActivityChart from '../components/dashboard/TimeSpentActivityChart.vue';
import SalesmanRatingChart from '../components/dashboard/SalesmanRatingChart.vue';
import AccountDevelopmentChart from '../components/dashboard/AccountDevelopmentChart.vue';
import MjpActualCalendar from '../components/dashboard/MjpActualCalendar.vue';
import UserAktif from '../components/dashboard/UserAktif.vue';
import ASMPlans from '../components/dashboard/ASMPlans.vue';
import ComplianceToday from '../components/dashboard/ComplianceToday.vue';
import ComplianceMtd from '../components/dashboard/ComplianceMtd.vue';
import Error from '../components/Error.vue';
// import TestingPolygonMap from '../components/dashboard/TestingPolygonMap.vue';
// import TestingClusterMap from '../components/dashboard/TestingClusterMap.vue';

export default {
	components: {
		'AccountDevelopmentMap': AccountDevelopmentMap,
		'TimeSpentActivityChart': TimeSpentActivityChart,
		'SalesmanRatingChart': SalesmanRatingChart,
		'AccountDevelopmentChart': AccountDevelopmentChart,
		'MjpActualCalendar': MjpActualCalendar,
		'UserAktif': UserAktif,
		'ASMPlans': ASMPlans,
		'ComplianceToday': ComplianceToday,
		'ComplianceMtd': ComplianceMtd,
		'Error': Error,
		// 'TestingPolygonMap': TestingPolygonMap,
		// 'TestingClusterMap': TestingClusterMap,
	},
    computed:{
        ...mapGetters(['errors']),
    },
	data() {
		return {		
			appsName: process.env.VUE_APP_APPS_NAME,

            // loading
            loadingDropdownUser: false,

            // dataDropdown
            dataDropdownUser: null,

            // filter
            filters: {
                userid: null,
            },
            userids: null,
		}
	},
    mounted() {
        this.searchDropdownUser('');
    },
    watch: {
        userids() {
            this.filters.userid = this.userids ? this.convertMultiSelectUser(this.userids) : null;
        },
    },
	methods: {
        convertMultiSelectUser(data) {
            if(data){
                return data.map((item) => {
                    return item.userid;
                });
            }
        },
        clearFilter(){
            this.filters.userid = null;
            this.userids = null;
        },
        searchDropdownUser(event, purpose, valueEdit) {
            setTimeout(() => {
                if (valueEdit) {
                    this.$refs.duser.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownUser = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/filter-userapp',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownUser = res.data.data;
                            this.loadingDropdownUser = false;

                            //Multiple Selected
                            if(this.userids){
                                this.dataDropdownUser.push(...this.userids);

                                const uniqueids= [];
                                this.dataDropdownUser = this.dataDropdownUser.filter((c) => {
                                    const isDup = uniqueids.includes(c.userid);
                                    if(!isDup){
                                        uniqueids.push(c.userid);
                                        return true;
                                    } 
                                    return false;
                                });
                            }

                        } else if (purpose == null) {
                            this.dataDropdownUser = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
	}
}
</script>