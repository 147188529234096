<template>
    <div class="p-fluid">
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Activity</label>
                <MultiSelect id="activity_id" dataKey="activity_id" v-model="activity_ids" :loading="loadingDropdownActivity"
                    :options="dataDropdownActivity"
                    optionLabel="activity_name" placeholder="Pilih Activity" :filter="true" :showToggleAll="false"
                    :showClear="true" @filter="searchDropdownActivity($event, 'filter')"/>
            </div>
        </div>
    </div>
     <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center mb-2">
        <div>
            <Button type="button" :label="'Total '+this.total_visit" icon="pi pi-users" class="p-button-primary"/>
        </div>
        <span class="block mt-2 md:mt-0 p-input-icon-left">
            <Button icon="pi pi-refresh" :loading="loading" class="p-button-warning" @click="showMarker()"/>
        </span>
    </div>
    <BlockUI :blocked="loading">
        <div class="map" id="account_dev_map"></div>
    </BlockUI>
</template>

<script>

// eslint-disable-next-line no-undef
var markers=  L.markerClusterGroup();

export default {
    props: [
		"filter",
    ],
    data() {
        return {
            // loading
            loadingDropdownActivity: false,

            // dataDropdown
            dataDropdownActivity: null,

            filters: {
                activity_id: null
            },
            activity_ids: null,

            //maps
            loading: false,
            map: null,
            zoom: 5,
            lat: -3.375126556326247,
            lng: 118.84902874210889,
            location_list: [],
            total_visit: 0
        }
    },
    mounted() {
        // eslint-disable-next-line no-undef
        this.map = L.map('account_dev_map', {
            attributionControl: false,
            doubleClickZoom: true,
            zoomControl: true,
            zoom:1,
            zoomAnimation:false,
            fadeAnimation:true,
            markerZoomAnimation:true
        }).setView([this.lat, this.lng], this.zoom);

        // eslint-disable-next-line no-undef
        L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
            attribution: '© OpenStreetMap'
        }).addTo(this.map);

        this.searchDropdownActivity('');

        this.showLegend();
    },
    created(){
        this.showMarker();
    },
    watch: {
        filter: {
            handler() {
                setTimeout(() => {
                    this.showMarker();
                }, 500);
            },
            deep: true
        },
        filters: {
            handler() {
                setTimeout(() => {
                    this.showMarker();
                }, 500);
            },
            deep: true
        },
        activity_ids() {
            this.filters.activity_id = this.activity_ids ? this.convertMultiSelectActivity(this.activity_ids) : null;
        },
    },
    methods:{
        convertMultiSelectActivity(data) {
            if(data){
                return data.map((item) => {
                    return item.activity_id;
                });
            }
        },
        // DROPDOWN
        searchDropdownActivity(event, purpose, valueEdit) {
            setTimeout(() => {
                if (valueEdit) {
                    this.$refs.dactivity.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownActivity = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-activity',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownActivity = res.data.data;
                            this.loadingDropdownActivity = false;

                            //Multiple Selected
                            if(this.activity_ids){
                                this.dataDropdownActivity.push(...this.activity_ids);

                                const uniqueids= [];
                                this.dataDropdownActivity = this.dataDropdownActivity.filter((c) => {
                                    const isDup = uniqueids.includes(c.activity_id);
                                    if(!isDup){
                                        uniqueids.push(c.activity_id);
                                        return true;
                                    } 
                                    return false;
                                });
                            }

                        } else if (purpose == null) {
                            this.dataDropdownActivity = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        showMarker(){

            this.loading= true;

            markers.clearLayers();
            
            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/dashboard/account-development-maps',
                params: {
                    "userid": this.filter.userid,
                    "activity_id": this.filters.activity_id,
                }
            })
            .then(res => {
                this.location_list = res.data.data.data;
                this.total_visit = res.data.data.data.length;
                // eslint-disable-next-line no-unused-vars
                this.location_list.map(function(value, key) {
                    if(value.lat != null && value.lng != null){

                        var htmlcontent = "<b>Tanggal</b> : " + value.tanggal + "</b><br/>";
                            htmlcontent += "<b>Activity</b> : " + value.activity_name + "<br/>";
                            htmlcontent += "<b>Distributor</b> : " + value.distributor_name + "<br/>";

                        var iconUrl = '';

                        if (value.activity_id == "1") {
                            iconUrl = "/marker/red.png"; 
                        }else if(value.activity_id == "2"){
                            iconUrl = "/marker/black.png"; 
                        }else if(value.activity_id == "3"){
                            iconUrl = "/marker/green.png"; 
                        }else if(value.activity_id == "4"){
                            iconUrl = "/marker/blue.png";
                        }else if(value.activity_id == "5"){
                            iconUrl = "/marker/brown.png";
                        }else if(value.activity_id == "6"){
                            iconUrl = "/marker/yellow.png";
                        }else {
                            iconUrl = "/marker/default.png";
                        }
                        
                        // eslint-disable-next-line no-undef
                        var icon = L.icon({
                            iconUrl: iconUrl,
                            outlet_id: value.outlet_id,
                            iconSize: [21, 36], // size of the icon
                            // shadowSize:   [50, 64], // size of the shadow
                            iconAnchor: [22, 40], // point of the icon which will correspond to marker's location
                            shadowAnchor: [4, 62], // the same for the shadow
                            popupAnchor: [-10, -46], // point from which the popup should open relative to the iconAnchor
                        })

                        // eslint-disable-next-line no-undef
                        var marker = L.marker([value.lat, value.lng],
                            {
                                icon: icon,
                            });


                        // eslint-disable-next-line no-unused-vars
                        var popup = marker.bindPopup(htmlcontent);
                        markers.addLayer(marker);
                    }
                }); 
                
                this.map.addLayer(markers);

                this.loading= false;
            })
            .catch((err) => {
                this.loading= false;
                console.log(err);
            });
        },
        showLegend() {
            setTimeout(() => {
                // eslint-disable-next-line no-undef
                var legend = L.control({ position: "bottomleft" });

                legend.onAdd = function () {

                    // eslint-disable-next-line no-undef
                    var div = L.DomUtil.create("div", "legend");
                    div.innerHTML += "<h4>Keterangan</h4>";
                    div.innerHTML += '<i style="background: red"></i><span>1.Sales Review</span><br>';
                    div.innerHTML += '<i style="background: black"></i><span>2.Business Review</span><br>';
                    div.innerHTML += '<i style="background: green"></i><span>3.Admin Work</span><br>';
                    div.innerHTML += '<i style="background: blue"></i><span>4.Join Visit</span><br>';
                    div.innerHTML += '<i style="background: brown"></i><span>5.Field Tracking</span><br>';
                    div.innerHTML += '<i style="background: yellow"></i><span>6.Account Dev</span><br>';

                    return div;
                };

                legend.addTo(this.map);
            }, 250);
        },
    }
}
</script>

<style>
	.map {
		height: 710px;
        width: 100%;
	}
    /*Legend specific*/
    .legend {
        padding: 6px 8px;
        font: 14px Arial, Helvetica, sans-serif;
        background: white;
        background: rgba(255, 255, 255, 0.8);
        /*box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);*/
        /*border-radius: 5px;*/
        line-height: 24px;
        color: #555;
    }

    .legend h4 {
        text-align: center;
        font-size: 16px;
        margin: 2px 12px 8px;
        color: #777;
    }

    .legend span {
        position: relative;
        bottom: 3px;
    }

    .legend i {
        width: 18px;
        height: 18px;
        float: left;
        margin: 0 8px 0 0;
        opacity: 0.7;
    }

    .legend i.icon {
        background-size: 18px;
        background-color: rgba(255, 255, 255, 1);
    }
</style>
